<template>
  <div class="border">
    <div class="title-border se-flex">
      <div class="title-icon" />
      <span class="title se-flex-1">设备数据统计</span>
    </div>

    <div class="info-border">
      <a-spin
        :spinning="loading"
        class="se-spin-stretch"
      >
        <div class="se-flex se-items-center se-height-100p">
          <a-icon :component="DeviceIcon" class="info-icon se-flex-1" />
          <a-statistic :value="data.num" class="se-flex-1" title="设备总数">
            <template #suffix>
              个
            </template>
          </a-statistic>
        </div>
      </a-spin>
    </div>
  </div>
</template>

<script>
import { findDevicesNum } from '@/api/home'
import DeviceIcon from '@/assets/images/home_device.svg'

export default {
  name: 'DeviceStatistic',
  data() {
    return {
      loading: true,
      data: {},
      DeviceIcon
    }
  },
  created() {
    this.fetchData()
  },
  methods: {
    fetchData() {
      this.loading = true
      findDevicesNum().then(res => {
        if (res.code === 0) {
          this.data = res.data
        }
        this.loading = false
      })
    }
  }
}
</script>

<style lang="less" scoped>
.border {
  margin: 10px;
}

.title-border {
  padding: 10px;

  .title-icon {
    width: 5px;
    height: 17px;
    background-color: rgba(64, 158, 255, 1);
    margin-top: 2px;
  }

  .title {
    font-weight: 700;
    margin-left: 10px;
  }
}

.info-border {
  height: 120px;
  margin: 10px;
  border-radius: 5px;
  background: linear-gradient(135deg, rgba(27, 190, 219, 1) 0%, rgba(27, 190, 219, 1) 0%, rgba(19, 206, 102, 1) 100%, rgba(19, 206, 102, 1) 100%);;
  color: white;

  .info-icon {
    font-size: 50px;
  }
}
</style>
