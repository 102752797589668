<template>
  <div class="border">
    <div class="title-border se-flex">
      <div class="title-icon" />
      <span class="title se-flex-1">设备种类分布</span>
    </div>

    <div class="info-border se-flex se-items-center">
      <a-spin
        :spinning="loading"
        class="se-spin-stretch"
      >
        <div ref="distribution" style="height: 120px;" />
      </a-spin>
    </div>
  </div>
</template>

<script>
import { findDevicesTypeDistribution } from '@/api/home'

export default {
  name: 'DeviceTypeDistribution',
  created() {
    window.addEventListener('resize', this.handleWindowResize)
  },
  mounted() {
    this.fetchData()
  },
  destroyed() {
    window.removeEventListener('resize', this.handleWindowResize)
  },
  computed: {
    chartOption() {
      return {
        tooltip: {
          trigger: 'item'
        },
        series: [
          {
            data: [],
            type: 'pie',
            radius: '70%',
            labelLine: {
              show: false
            },
            label: {
              show: true,
              position: 'center'
            }
          }
        ]
      }
    }
  },
  data() {
    return {
      loading: true,
      chart: null,
      data: {}
    }
  },
  methods: {
    handleWindowResize() {
      this.chart && this.chart.resize()
    },

    fetchData() {
      this.loading = true
      findDevicesTypeDistribution().then(res => {
        if (res.code === 0) {
          this.data = res.data
          this.drawChart()
        }
        this.loading = false
      })
    },

    drawChart() {
      this.chart ||= this.$echarts.init(this.$refs.distribution, 'echarts_dark')
      this.chartOption.series[0].data = this.data.statistics
      this.chart.setOption(this.chartOption, true)
    }
  }
}
</script>

<style lang="less" scoped>
.border {
  margin: 10px;
}

.title-border {
  padding: 10px;

  .title-icon {
    width: 5px;
    height: 17px;
    background-color: rgba(64, 158, 255, 1);
    margin-top: 2px;
  }

  .title {
    font-weight: 700;
    margin-left: 10px;
  }
}

.info-border {
  height: 120px;
  margin: 10px;
  border-radius: 5px;
  background: linear-gradient(135deg, rgba(0, 180, 255, 1) 0%, rgba(0, 180, 255, 1) 0%, rgba(0, 122, 254, 1) 100%, rgba(0, 122, 254, 1) 100%);
  color: white;

  .info-icon {
    font-size: 50px;
  }
}
</style>
