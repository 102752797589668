import request from '@/utils/request'

// 首页-获取客户总数
export function findCustomersNum() {
  return request({
    url: `/admin/home/customers_num`,
    method: 'get'
  })
}

// 首页-获取客户趋势
export function findCustomersThread() {
  return request({
    url: `/admin/home/customers_thread`,
    method: 'get'
  })
}

// 首页-获取设备总数
export function findDevicesNum() {
  return request({
    url: `/admin/home/devices_num`,
    method: 'get'
  })
}

// 首页-获取设备类型分布
export function findDevicesTypeDistribution() {
  return request({
    url: `/admin/home/devices_type_distribution`,
    method: 'get'
  })
}

// 首页-获取设备趋势
export function findDevicesThread() {
  return request({
    url: `/admin/home/devices_thread`,
    method: 'get'
  })
}
