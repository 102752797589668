<template>
  <div class="se-height-100p">
    <div class="se-flex se-flex-row se-height-100p box">
      <div class="se-flex-1 box-shadow">
        <customer-statistic />
        <customer-thread />
      </div>
      <div class="se-flex-1 box-shadow">
        <device-statistic />
        <device-type-distribution />
        <device-thread />
      </div>
      <div class="se-flex-1" />
    </div>
  </div>
</template>

<script>
import CustomerStatistic from '@/views/home/modules/CustomerStatistic'
import CustomerThread from '@/views/home/modules/CustomerThread'
import DeviceStatistic from '@/views/home/modules/DeviceStatistic'
import DeviceTypeDistribution from '@/views/home/modules/DeviceTypeDistribution'
import DeviceThread from '@/views/home/modules/DeviceThread'

export default {
  name: 'Home',
  components: {
    DeviceThread,
    DeviceTypeDistribution,
    DeviceStatistic,
    CustomerStatistic,
    CustomerThread
  },
  methods: {
    handleLogout() {
      return this.$store.dispatch('Logout').then(() => {
        this.$router.push({ name: 'login' })
      })
    }
  }
}
</script>

<style lang="less" scoped>
.box {
  padding: 2px;
}

.box-shadow {
  box-shadow: 0 0 10px #ccc;
  margin: 0 10px;
  min-width: 450px;
  max-width: 550px;
}
</style>
